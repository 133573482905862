<!--
File: TreatmentRates.vue
Description: show list of Rates entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addRate"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" @md-selected.native="onSelect"
        :md-sort.sync="currentSort" md-fixed-header :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('stdCols.description')" md-sort-by="treatment_type_description">
            {{ item.treatment_type_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.key')" md-sort-by="treatment_type_key">
            {{ item.treatment_type_key }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.units')" md-sort-by="unit_description">
            {{ item.unit_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.number_units')" md-sort-by="unit_quantity">
            {{ item.unit_quantity | numFormat(0) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.rate')" md-sort-by="rate" md-numeric>
            {{ item.rate | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="editRate(item.treatment_rate_id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for="item in footerTable" :key="item.name" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
  import { Pagination } from '@/components'
  import Fuse from 'fuse.js'
  import { customSortMixin } from '@/mixins/customSortMixin'

  export default {
    mixins: [customSortMixin],
    name: 'treatment-rates-list',
    data() {
      return {
        showSpinner: false,
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['treatment_type_description', 'unit_description', 'rate', 'date'],
        searchedData: [],
        currentSort: 'treatment_type_description',
        currentSortOrder: 'asc',
        fuseSearch: null,
        footerTable: []
      }
    },
    components: {
      Pagination
    },
    mounted() {
      this.showSpinner = true
      this.$store.dispatch('LOAD_HISTORY').then((history) => {
        console.log(history)
        if (history.for === 'treatment_rates' && history.use) {
          this.currentSort = history.data.currentSort
          this.currentSortOrder = history.data.currentSortOrder
          this.pagination.perPage = history.data.perPage
          this.pagination.currentPage = history.data.currentPage
          this.$store.dispatch('CLEAR_HISTORY')
        }
        this.$store.dispatch('LOAD_TREATMENT_RATE_LIST').then(() => {
          //console.log(this.tableData)
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          })
          this.$nextTick(() => {
            this.searchQuery = history.data.searchQuery
          })
        })
      })
      this.showSpinner = false
    },

    methods: {
      save_history() {
        const hist = {
          searchQuery: this.searchQuery,
          currentSort: this.currentSort,
          currentSortOrder: this.currentSortOrder,
          perPage: this.pagination.perPage,
          currentPage: this.pagination.currentPage
        }
        this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'treatment_rates' })
      },
      editRate(id) {
        this.save_history()
        this.$router.push(`/treatments/rates/upd/${id}`)
      },
      addRate() {
        this.save_history()
        this.$router.push(`/treatments/rates/add`)
      },
      handleDelete(item) {
      }
    },
    computed: {
      amIAdmin() {
        return this.$store.getters.amIAdmin
      },
      tableData() {
        return this.$store.state.TreatmentRates.list
      },
      queriedData() {
        const result = !this.searchQuery ? this.tableData : this.searchedData ? this.searchedData : []
        return result.slice(this.from, this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total() {
        return this.searchQuery ? this.searchedData.length : this.tableData.length
      }
    },
    watch: {
      searchQuery(value) {
        if (this.tableData.length == 0) {
          this.searchedData = []
          return
        }
        this.searchedData = (value !== '') ? this.fuseSearch.search(this.searchQuery) : this.tableData
      }
    }
  }
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>